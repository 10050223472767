import React from 'react'
import SwiperCore, { Navigation } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import styled, { css } from 'styled-components'
import { toCssPrefix, breakpoints } from '@ecommerce/shared'
import BrandItem from './BrandItem'
import SkeletonItem from '../Skeletons/SkeletonItem'
import { getBorder } from '../PromoList'
import SectionTitle from '../Moments/SectionTitle'

interface Brand {
  id: string
  image: {
    url: string
  }
  category?: {
    name?: string
    mainCategoryName?: string
  }
}

type Props = React.HTMLAttributes<HTMLDivElement> & {
  allowTitleLowerCase?: boolean
  title?: string
  titleSize?: string
  titleColor?: string
  titleBorderColor?: string
  titleBorderColorShadowNeon?: string
  centerTitle?: boolean
  brands?: Brand[]
  testId?: string
  slidesTestId?: string
  loading?: boolean
  brandShadow?: boolean
}

const { cssPrefix, toPrefix } = toCssPrefix('BrandsSlider__')

const Wrapper = styled.div<Props>`
  margin: 0 auto;
  padding: 12px 20px 20px 20px;
  .${cssPrefix} {
    &slide {
      width: 120px;
      height: 120px;
    }

    &title {
      font-size: 24px;
      color: ${({ titleColor }) => titleColor || 'inherit'};
      text-align: ${({ centerTitle }) => (centerTitle ? 'center' : 'left')};
      ${({ titleBorderColor, titleBorderColorShadowNeon }) => getBorder(titleBorderColor, titleBorderColorShadowNeon)}
      ${({ allowTitleLowerCase }) =>
        !allowTitleLowerCase &&
        css`
          text-transform: uppercase;
        `}
    }

    &swiper {
      padding: 12px 0 20px 0;
      .swiper-button {
        padding: 12px 0 20px 0px;
        &-next,
        &-prev {
          color: ${({ theme }) => theme.colors.white};
          background-color: ${({ theme }) => theme.colors.red};
          display: none;
          opacity: 0.5;
          border-radius: 50%;
          height: 40px;
          width: 40px;
          padding: 0;
          transition: 200ms;

          &::after {
            font-weight: bold;
            font-size: 16px;
          }
        }

        &-next {
          right: 0;
        }

        &-prev {
          left: 0;
        }
      }
    }
  }

  @media screen and (${breakpoints.desktop.min}) {
    padding: 0 90px 0 90px;
    .${cssPrefix} {
      &swiper {
        padding: 12px 0 20px 0;
        .swiper-button {
          &-next,
          &-prev {
            display: flex;
          }
        }
      }
    }
  }
`
SwiperCore.use([Navigation])

const BrandsSlider = ({
  title,
  titleColor,
  titleSize,
  brands = [],
  className = '',
  testId = 'brands-slider',
  slidesTestId = 'brands-slider-slide',
  loading,
  brandShadow,
  ...props
}: Props) => {
  return (
    <Wrapper data-testid={testId} className={`${className} ${cssPrefix}`} {...props}>
      {title && <SectionTitle color={titleColor} text={title} {...props} />}
      <Swiper className={toPrefix('swiper')} navigation spaceBetween={20} slidesPerView="auto">
        {loading ? (
          Array(14)
            .fill(0)
            .map((_, index) => (
              <SwiperSlide className={toPrefix('slide')} key={index}>
                <SkeletonItem height={140} animate />
              </SwiperSlide>
            ))
        ) : (
          <>
            {brands?.map((brand) => (
              <SwiperSlide data-testid={slidesTestId} className={toPrefix('slide')} key={brand.image?.url}>
                <BrandItem
                  shadow={brandShadow}
                  categoryName={brand.category?.name}
                  name={brand.id}
                  image={brand.image?.url}
                />
              </SwiperSlide>
            ))}
          </>
        )}
      </Swiper>
    </Wrapper>
  )
}

export default BrandsSlider
