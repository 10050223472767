import React from 'react'
import styled from 'styled-components'
import { Image } from '@ecommerce/shared'
import { navigate } from 'gatsby'
import { Anchor } from '../common'

type Props = {
  name?: string
  image?: string
  shadow?: boolean
  categoryName?: string
}

const Wrapper = styled.div<Props>`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.colors.brandText.default};
  border-radius: ${({ theme }) => theme.borderRadius};
  padding: 5px;
  box-shadow: ${({ shadow, theme }) => (shadow ? theme.boxShadow.lvlTwo : 'none')};

  .anchor {
    width: 100%;
    height: 100%;
    cursor: ${({ categoryName }) => (categoryName ? 'pointer' : 'auto')};
  }

  .brand-image {
    width: 100%;
    height: 100%;
    display: block;
    margin: auto;
  }
`

const BrandItem = ({ image, categoryName, name, shadow }: Props) => {
  const onNavigate = (event: React.MouseEvent) => {
    event.preventDefault()
    if (categoryName && name) {
      navigate(`/category/${categoryName}?refinementList[brandName][0]=${name}`)
    }
  }

  return (
    <Wrapper categoryName={categoryName} shadow={shadow}>
      <Anchor className="anchor" onClick={onNavigate}>
        <Image
          className="brand-image"
          src={image}
          alt="brand-item"
          params={{
            q: 90,
          }}
        />
      </Anchor>
    </Wrapper>
  )
}

export default BrandItem
