import React from 'react'
import BrandsSlider from '.'
import useQuery from '../../hooks/useQuery'
import { BrandFragment, OrListFragment } from '../../graphql/fragments'
import { Block } from '../../graphql/contentfulTypes'

type Props = {
  className?: string
  listId: string
  testId?: string
}

const query = `query BrandsList($listId: String!) {
    orList(id: $listId) {
      ...OrListFragment
      itemsCollection {
        items {
          ...BrandFragment
        }
      }
    }
  }
  ${OrListFragment}
  ${BrandFragment}
`

export type QueryResult = {
  orList: {
    title: string
    itemsCollection: {
      items: Array<{
        id: string
        image: {
          url: string
        }
        category?: {
          name?: string
          mainCategoryName?: string
        }
      }>
    }
  }
}

const BrandsSliderWidget = ({ className, listId, testId = 'brands-slider-widget' }: Props) => {
  const { data, loading } = useQuery<QueryResult>(query, { variables: { listId } })

  const list = data?.orList
  const brands = list?.itemsCollection?.items

  return (
    <BrandsSlider
      className={className}
      testId={testId}
      brands={brands}
      title={list?.title}
      loading={loading}
      {...list}
    />
  )
}

export default BrandsSliderWidget
