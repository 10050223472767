import { useEffect, useState } from 'react'
import algoliasearch, { SearchClient } from 'algoliasearch'
import { MultipleQueriesResponse, SearchForFacetValuesQueryParams, SearchResponse } from '@algolia/client-search'
import { AlgoliaProduct, log } from '@ecommerce/shared'
import secrets from '../../config/secrets'

interface QueryWithId {
  indexName: string
  query: string
  params?: SearchForFacetValuesQueryParams
  queryId: string
}

const useMultipleQueries = (queries: QueryWithId[]): [SearchResponse<AlgoliaProduct>[] | null, boolean] => {
  const [results, setResults] = useState<(SearchResponse<AlgoliaProduct> & { queryID: string })[] | null>(null)
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    const client: SearchClient = algoliasearch(secrets.ALGOLIA_APP_ID, secrets.ALGOLIA_API_KEY)

    if (queries.length > 0) {
      const queriesWithoutQueryId = queries.map((query) => {
        return {
          indexName: query.indexName,
          params: query.params,
        }
      })

      setLoading(true)
      client
        .multipleQueries<AlgoliaProduct>(queriesWithoutQueryId)
        .then((res: MultipleQueriesResponse<AlgoliaProduct>) => {
          if (res && Array.isArray(res.results)) {
            const resultsWithId = res.results.map(
              (result, index) =>
                ({
                  ...result,
                  queryID: queries[index].queryId,
                } as SearchResponse<AlgoliaProduct> & { queryID: string }),
            )
            setResults(resultsWithId)
          }
        })
        .catch((error: Error) => {
          log.error('Error occurred while fetching Algolia', error)
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }, [queries])

  return [results, loading]
}

export default useMultipleQueries
