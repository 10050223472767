import React, { useMemo, useEffect } from 'react'
import styled, { css } from 'styled-components'
import {
  breakpoints,
  toCssPrefix,
  useLocation,
  Loading,
  getStoredDistributionCenter,
  DistributionCenter,
  getCurrencyCode,
} from '@ecommerce/shared'
import secrets from '../../../config/secrets'
import Slide from '../ProductSlide'
import { ClientSideProductSlide } from '../../../graphql/contentfulTypes'
import Slider from '../../FramerSlider'
import { useCartStockErrorHandler } from '../../../utils/errors'
import useAlgoliaFetch from '../../../hooks/useAlgoliaFetch'
import useQuery from '../../../hooks/useQuery'
import { getBorder } from '../../PromoList'
import SectionTitle from '../SectionTitle'
import productSlideQuery, { QueryProductSlideResult } from '../../../graphql/productSlideQuery'
import { sendViewItemListEvent } from '../../../utils/events'

const { cssPrefix, toPrefix } = toCssPrefix('ProductSlidesList__')

type Props = {
  listId?: string
  themeId?: string
  className?: string
  currentDistributionCenter?: DistributionCenter
}

const Wrapper = styled.div<{
  allowTitleLowerCase?: boolean
  backgroundImage?: string
  customBackground?: string
  centerTitle?: boolean
  titleBorderColor?: string
  titleBorderColorShadowNeon?: string
  titleColor?: string
}>`
  display: flex;
  flex-direction: column;
  color: ${({ theme }) => theme.colors.bodyText};
  position: relative;

  .${cssPrefix} {
    &title {
      color: ${({ titleColor }) => titleColor || 'inherit'};
      text-align: ${({ centerTitle }) => (centerTitle ? 'center' : 'left')};
      ${({ titleBorderColor, titleBorderColorShadowNeon }) => getBorder(titleBorderColor, titleBorderColorShadowNeon)}
      ${({ allowTitleLowerCase }) =>
        !allowTitleLowerCase &&
        css`
          text-transform: uppercase;
        `}
    }

    &loader {
      position: absolute;
      width: 84px;
      height: 84px;
      left: calc(50% - 42px);
      top: calc(50% - 42px);
    }

    &slider {
      padding: 20px 0; 
      margin-top: 32px;
        ${({ backgroundImage, customBackground }) => {
          if (backgroundImage) {
            return css`
              background: url(${backgroundImage});
            `
          }
          return css`
            background: ${({ theme }) => customBackground || theme.colors.background.body};
          `
        }}

    &slider-inner {
      overflow-y: scroll;
    }

    &slider-nav {
      &.next {
        right: 30px;
      }

      &.prev {
        left: 30px;
      }
    }
  }

  @media screen and (${breakpoints.desktop.min}) {
    background: linear-gradient(0deg, ${({ theme }) => theme.colors.background.bodyAlternative} 80%, transparent 0);
    padding-top: 0;

    .${cssPrefix} {
      &slider-inner {
        overflow-y: auto;
      }
    }
  }
`

const ProductsSlidesList = ({ themeId, listId, className, currentDistributionCenter }: Props) => {
  const storedDistributionCenter = getStoredDistributionCenter() || currentDistributionCenter

  const [slides, setSlides] = React.useState<ClientSideProductSlide[]>([])

  const stockErrorHandler = useCartStockErrorHandler()
  const { toCurrency } = useLocation()
  const { COUNTRY } = secrets

  const { data: clientSideData, executeQuery, loading } = useQuery<QueryProductSlideResult>(productSlideQuery, {
    variables: { listId: listId ?? '' },
    inmediate: false,
  })

  useEffect(() => {
    if (!clientSideData?.orList.itemsCollection.items && listId) {
      executeQuery()
    } else if (
      clientSideData?.orList.itemsCollection.items?.length &&
      clientSideData?.orList.itemsCollection.items?.length > 0
    ) {
      setSlides(clientSideData?.orList.itemsCollection.items)
    }
  }, [clientSideData?.orList.itemsCollection.items, listId])

  useEffect(() => {
    if (clientSideData?.orList.itemsCollection?.items?.length && !loading) {
      setSlides(clientSideData?.orList.itemsCollection.items)
    }
  }, [clientSideData, loading])

  const skus =
    useMemo(
      () =>
        slides?.map((item) => {
          return item.product.sku
        }),
      [slides],
    ) ?? []

  const { products, loadingProducts } = useAlgoliaFetch({
    distributionCenter: storedDistributionCenter,
    skus,
    listName: clientSideData?.orList?.name,
    navigationState: { themeId },
  })

  const isLoading = !clientSideData?.orList.itemsCollection.items ? loading || loadingProducts : loadingProducts

  useEffect(() => {
    if (products && products.length > 0 && !loadingProducts) {
      sendViewItemListEvent({ products, currency: getCurrencyCode(COUNTRY), list: clientSideData?.orList.title })
    }
  }, [products, loadingProducts])

  const renderProduct = (product: typeof products[0]) => {
    const data = slides?.find((item) => item.product.sku === String(product.skuCode))
    if (!data) return null

    return (
      <Slide
        isLoading={loadingProducts}
        algoliaProduct={product}
        key={product.skuCode}
        formattedPrice={toCurrency(product.price)}
        originalPrice={product.rawPrice > product.price ? toCurrency(product.rawPrice) : undefined}
        stockErrorHandler={stockErrorHandler}
        data={data}
        className={className}
      />
    )
  }

  return (
    <Wrapper
      className={cssPrefix}
      backgroundImage={clientSideData?.orList.background?.url}
      customBackground={clientSideData?.orList.backgroundColor}
    >
      {clientSideData?.orList.title && (
        <SectionTitle
          text={clientSideData?.orList.title}
          color={clientSideData?.orList.titleColor}
          {...clientSideData?.orList}
        />
      )}
      {isLoading ? (
        <Loading className={toPrefix('loader')} />
      ) : (
        <Slider dragDirectionLock useBullets className={toPrefix('slider')} itemsCount={products.length}>
          {products?.map(renderProduct)}
        </Slider>
      )}
    </Wrapper>
  )
}

export default ProductsSlidesList
