import React from 'react'
import styled, { css } from 'styled-components'
import {
  toCssPrefix,
  Image,
  breakpoints,
  AddProductButton,
  Button,
  useShoppingCart,
  CartStockErrorHandler,
  ProductCart,
} from '@ecommerce/shared'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { ClientSideProductSlide } from '../../../graphql/contentfulTypes'
import SkeletonItem from '../../Skeletons/SkeletonItem'

const { cssPrefix, toPrefix } = toCssPrefix('ProductSlide__')

type Props = {
  data: ClientSideProductSlide
  algoliaProduct: ProductCart
  formattedPrice?: string
  originalPrice?: string
  isLoading?: boolean
  stockErrorHandler?: CartStockErrorHandler
  className?: string
  testId?: string
}

type WrapperProps = {
  addSpace?: boolean
  bodyColor?: string
  priceColor?: string
  originalPriceColor?: string
}

const Wrapper = styled.div<WrapperProps>`
  width: 100vw;
  height: auto;

  .${cssPrefix} {
    &content {
      padding: 0 20px;
      color: ${({ theme }) => theme.colors.bodyText};
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 20px;
      ${({ addSpace }) =>
        addSpace &&
        css`
          padding-bottom: 54px;
        `}
    }

    &product-image {
      width: ${({ addSpace }) => (addSpace ? '233px' : '240px')};
      height: auto;
      object-fit: contain;
      margin-bottom: ${({ addSpace }) => (addSpace ? '31px' : '70px')};
      user-select: none;
      user-drag: none;
      ${({ addSpace }) =>
        addSpace &&
        css`
          margin-top: 31px;
        `}
    }

    &subtitle {
      font-size: 18px;
      margin: 0;
      display: block;
    }

    &title {
      font-size: ${({ addSpace }) => (addSpace ? '30px' : '24px')};
      line-height: 29px;
      margin: 10px 0;
      ${({ addSpace }) =>
        addSpace &&
        css`
          margin-bottom: 20px;
        `}
    }

    &price-skeleton {
      margin: 0 auto;
    }

    &original-price {
      color: ${({ originalPriceColor, theme }) => originalPriceColor || theme.colors.bodyText};
      text-decoration: line-through;
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
      letter-spacing: -0.25px;
      margin-bottom: 0;
    }

    &price {
      font-size: 24px;
      line-height: 29px;
      font-weight: bold;
      color: ${({ theme, priceColor }) => priceColor || theme.colors.grey60};
      margin: 0;
    }

    &description {
      font-size: ${({ addSpace }) => (addSpace ? '17px' : '14px')};
      line-height: 18px;
      margin: 20px 0 30px;
      ${({ addSpace }) =>
        addSpace &&
        css`
          margin-top: 28px;
          line-height: 25px;
          letter-spacing: -0.24px;
          font-weight: 325;
          margin-bottom: 41px;
        `}
    }

    &title,
    &subtitle,
    &description {
      color: ${({ bodyColor, theme }) => bodyColor || theme.colors.bodyText};
    }

    &add-button-skeleton {
      margin: 0 auto 25px;
    }

    &add-product-button {
      height: 40px;
      font-size: 17px;
      margin-bottom: 8px;
    }

    &detail-button,
    &add-product-button {
      width: 100%;
    }
  }

  @media screen and (${breakpoints.tabletLandscape.min}) {
    .${cssPrefix} {
      &content {
        flex-direction: row;
        padding: 0 24px;
        text-align: left;
        margin: 0;
      }

      &product-image {
        margin: 0 20px 0 0;
        width: ${({ addSpace }) => (addSpace ? '233px' : '300px')};
      }

      &price-skeleton {
        margin: 0;
      }

      &title {
        margin: 0;
      }

      &add-button-skeleton {
        margin: 0 0 25px;
      }

      &add-product-button {
        margin-bottom: 0;
      }

      &buttons {
        display: flex;
      }

      &detail-button {
        margin-left: 22px;

        &[disabled] {
          pointer-events: none;
        }
      }

      &add-product-button {
        margin-left: 0;
        margin-right: 0;
      }

      &description {
        margin: 15px 0;
      }
    }
  }

  @media screen and (${breakpoints.desktop.min}) {
    .${cssPrefix} {
      &content {
        padding: 0 100px;
      }

      &title {
        font-size: 28px;
        line-height: 34px;
      }

      &description {
        font-size: 16px;
        line-height: 20px;
      }

      &product-image {
        width: 320px;
        height: 320px;
        object-fit: contain;
        margin-right: 100px;
      }
    }
  }

  @media screen and (${breakpoints.desktopWide.min}) {
    ${({ addSpace }) =>
      addSpace &&
      css`
        padding-top: 37px;
        padding-bottom: 59px;
      `}

    .${cssPrefix} {
      &content {
        padding: 0 200px;
      }

      &content__right {
        display: flex;
        flex-direction: column;
      }

      &product-image {
        width: 380px;
        height: 380px;
        margin-right: 200px;
      }

      ${({ addSpace }) =>
        addSpace &&
        css`
          &title {
            font-size: 34px;
            line-height: 40px;
            margin-bottom: 30px;
          }

          &price {
            font-size: 30px;
            line-height: 40px;
          }

          &description {
            font-weight: 400;
            font-size: 18px;
            line-height: 22px;
            letter-spacing: -0.254118px;
            min-height: 215px;
          }
        `}
    }
  }
`

const ProductSlide = ({
  data,
  stockErrorHandler,
  formattedPrice,
  originalPrice,
  isLoading = false,
  algoliaProduct,
  className = '',
  testId = 'product-slide',
}: Props) => {
  const {
    subtitle,
    title,
    showPrice,
    description,
    showAddButton,
    showDetailsButton,
    image,
    bodyColor,
    priceColor,
    originalPriceColor,
    addSpace,
  } = data

  const {
    state: {
      byHash: { [algoliaProduct.skuCode]: slideProduct },
    },
  } = useShoppingCart()

  const productUnavailable = algoliaProduct?.unavailable

  return (
    <Wrapper
      data-testid={testId}
      className={cssPrefix}
      addSpace={addSpace}
      priceColor={priceColor}
      originalPriceColor={originalPriceColor}
      bodyColor={bodyColor}
    >
      <div className={toPrefix('content')}>
        <div className={toPrefix('content__left')}>
          <Image className={toPrefix('product-image')} src={image.url} alt={title || ''} />
        </div>
        <div className={toPrefix('content__right')}>
          {subtitle && <span className={toPrefix('subtitle')}>{subtitle}</span>}
          <h2 className={toPrefix('title')}>{title}</h2>
          {showPrice && originalPrice && (
            <>
              {isLoading ? (
                <SkeletonItem className={toPrefix('price-skeleton')} width="100px" height={24} animate />
              ) : (
                <p className={toPrefix('original-price')}>{originalPrice}</p>
              )}
            </>
          )}
          {showPrice && (
            <>
              {isLoading ? (
                <SkeletonItem className={toPrefix('price-skeleton')} width="100px" height={24} animate />
              ) : (
                <p className={toPrefix('price')}>{formattedPrice}</p>
              )}
            </>
          )}
          {description && <div className={toPrefix('description')}>{documentToReactComponents(description.json)}</div>}
          <div className={toPrefix('buttons')}>
            {showAddButton && (
              <>
                {isLoading ? (
                  <SkeletonItem className={toPrefix('add-button-skeleton')} width="200px" height={40} animate />
                ) : (
                  <>
                    {!algoliaProduct || productUnavailable ? (
                      <Button isDisabled className={toPrefix('add-product-button')} customSecondary>
                        No disponible
                      </Button>
                    ) : (
                      <AddProductButton
                        className={`${toPrefix('add-product-button')} ${className}-add-product-button`}
                        quantity={slideProduct?.quantity ?? 0}
                        product={algoliaProduct}
                        stockErrorHandler={stockErrorHandler}
                        customButtonColor
                        justifyButton
                      />
                    )}
                  </>
                )}
              </>
            )}
            {showDetailsButton && (
              <Button
                disabled={productUnavailable}
                isDisabled={productUnavailable}
                btnType="secondary"
                onClick={algoliaProduct.onNavigate}
                className={`${toPrefix('detail-button')} ${className}-detail-button`}
                customSecondary
              >
                Ver producto
              </Button>
            )}
          </div>
        </div>
      </div>
    </Wrapper>
  )
}

export default ProductSlide
