import { OrListFragment, MlHeroFragment, OrProductSlideFragment } from './fragments'
import { BaseBlock, BlockType, ClientSideMlHero, ClientSideProductSlide } from './contentfulTypes'

export interface QueryProductSlideResult {
  orList: {
    blockType: BlockType.HERO
    itemsCollection: {
      items: ClientSideProductSlide[]
    }
  } & BaseBlock
}

export default `
  query ProductSlideQuery($listId: String!) {
    orList(id: $listId) {
      ...OrListFragment 
        itemsCollection {
          items {
            ...OrProductSlideFragment
          }
        }
      }
    }
  ${OrListFragment}
  ${OrProductSlideFragment}
`
